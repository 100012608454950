import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation } from 'react-i18next';
import qs from 'qs';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import Form from '../../../components/InstantVerification/2FAForm';
import UserBankStatement from '../../../components/InstantVerification/BankStatement';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import './style.scss';

type Props = PageProps & WithTranslation;

const BankStatement: React.FC<Props> = (props) => {
  const { t } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [linkInvalid, setLinkInvalid] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [error, setError] = useState('');

  const [data, setData] = useState({
    AccountDetails: {
      Accounts: [
        {
          Holder: {},
          Transactions: [],
        },
      ],
      Institution: '',
    },
  });

  const reset = () => {
    setCurrentStep(0);
    setError('');
    setLinkInvalid(false);
  };

  useEffect(() => {
    const { accountId: id } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (!id) {
      setLinkInvalid(true);
      return reset;
    }

    setCurrentStep(1);
    setAccountId(id as string);
    return reset;
  }, []);

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('instantVerification.seo.bankStatementTitle'),
    },
    page: {
      title: t('instantVerification.seo.bankStatementTitle'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  return (
    <Layout options={layoutOptions} className="BankStatement">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            {linkInvalid ? (
              <ErrorMessage>{t('instantVerification.linkValidationFailure')}</ErrorMessage>
            ) : null}
            {currentStep === 1 ? (
              <>
                <h4>{t('instantVerification.2FAMessage')}</h4>
                <Form
                  t={t}
                  setCurrentStep={setCurrentStep}
                  setError={setError}
                  setData={setData}
                  accountId={accountId}
                />
              </>
            ) : null}
            {error ? <ErrorMessage>{error}</ErrorMessage> : null}
          </section>
          {currentStep === 2 ? (
            <section className="column column-100">
              <UserBankStatement data={data} t={t} />
            </section>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.INSTANT_VERIFICATION)(BankStatement);
