import React from 'react';
import moment from 'moment';
import { TFunction } from 'i18next';

interface OwnProps {
  t: TFunction;
  data: any;
}

type Props = OwnProps;

const BankStatement: React.FC<Props> = ({ data, t }) => {
  const {
    Institution,
    Accounts: [
      {
        Holder: { Name },
        Title,
        AccountNumber: an,
      },
    ],
  } = data.AccountDetails;

  return (
    <>
      <div className="BankStatement__generic-gap">
        <div className="row">
          <div className="column column-50 text-left">
            <p>
              {t('instantVerification.bankStatement.institution')}: {Institution}
            </p>
            <p>
              {t('instantVerification.bankStatement.accountHolder')}
              {': '}
              {Name}
            </p>
            <p>
              {t('instantVerification.bankStatement.account')}: {Title}
            </p>
            <p>
              {t('instantVerification.bankStatement.accountNumber')}
              {': '}
              {an.substring(0, an.length - 4).replace(/\d/g, 'X')}
              {an.substring(an.length - 4, an.length)}
            </p>
          </div>
          <div className="column column-50 text-right">
            <p>{moment().format('MM/DD/YYYY h:mm:ss')}</p>
            <p>{t('instantVerification.bankStatement.transactionsAsOf')}</p>
          </div>
        </div>
      </div>
      <div className="BankStatement__generic-gap">
        <table>
          <thead>
            <tr>
              <th>{t('instantVerification.bankStatement.date')}</th>
              <th>{t('instantVerification.bankStatement.description')}</th>
              <th>{t('instantVerification.bankStatement.debit')}</th>
              <th>{t('instantVerification.bankStatement.credit')}</th>
              <th>{t('instantVerification.bankStatement.balance')}</th>
            </tr>
          </thead>
          <tbody>
            {(data.AccountDetails.Accounts[0].Transactions as any[]).map((row) => (
              <tr key={row.Id}>
                <td>{row.Date}</td>
                <td>{row.Description}</td>
                <td>{row.Debit}</td>
                <td>{row.Credit}</td>
                <td>{row.Balance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BankStatement;
