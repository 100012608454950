import React, { useState } from 'react';
import { TFunction } from 'i18next';
// import { LocaleNameSpaceKeys } from '../../localization/translations';

import Input, { InputType } from '../primitives/Input/Input';
import Button from '../primitives/Button/Button';

interface OwnProps {
  t: TFunction;
  setCurrentStep: (step: number) => void;
  setError: (val: string) => void;
  setData: (data: any) => void;
  accountId?: string;
}

type Props = OwnProps;

const Form: React.FC<Props> = ({ t, setCurrentStep, setError, setData, accountId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [code, setCode] = useState({
    value: '',
    validationMessage: '',
    isInvalid: false,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (code.value) {
      setIsSubmitting(true);
      setCode({ ...code, isInvalid: false });

      fetch(
        `${process.env.GATSBY_INSTANT_VERIFICATION_API_URL}?accountId=${accountId}&2FactorAuth=${code.value}`,
        {
          method: 'GET',
          // @ts-ignore
          headers: {
            'Content-Type': 'application/json',
            client_id: process.env.GATSBY_LENDING_AREAS_CLIENT_ID,
            client_secret: process.env.GATSBY_LENDING_AREAS_CLIENT_SECRET,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setIsSubmitting(false);

          if (data.errorCode) {
            setCurrentStep(0);
            setError(t(`instantVerification.2FAErrors.${data.errorCode}`));
          } else {
            setData(data);
            setCurrentStep(2);
          }
        })
        .catch(() => {
          setCurrentStep(0);
          setError(t('instantVerification.form.submissionValidation.error'));
        });
    }
  };

  const handleChange = (val: any) => {
    setCode({ ...code, value: val });
  };

  return (
    <form onSubmit={handleSubmit} className="BankStatement__generic-gap" noValidate>
      <Input
        id="code"
        isInvalid={code.isInvalid}
        validationMessage={code.validationMessage}
        options={{
          type: InputType.TEXT,
          label: '',
          inputProps: {
            name: 'code',
            value: code.value,
          },
        }}
        onChange={handleChange}
      />
      <Button
        id="2fa-submit"
        type="submit"
        styleOptions={{ isInline: true }}
        disabled={isSubmitting}
      >
        {t('instantVerification.form.submitLabel')}
      </Button>
    </form>
  );
};

export default Form;
